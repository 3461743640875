import React, { useState, useEffect, useMemo, useCallback } from 'react';
import '../css/App.css';
import Nav from './Nav';
import {
    APIlogout,
    APIauthUC,
    APIgenerateDocUC,
    APIgetDocUC
} from '../apicalls';
import {
    Container,
    Grid,
    Segment,
    Button,
    Icon,
    Input,
    Label
} from 'semantic-ui-react'

import DataTable from 'react-data-table-component'

const Admin = ({ role, logincheck, loading, size }) => {
    const [error, setError] = useState("")
    const [token, setToken] = useState("")
    const [dateStart, setDateStart] = useState("")
    const [dateEnd, setDateEnd] = useState("")

    const [arrData, setArrData] = useState("")

    const retriveDoc = useCallback(async (r, setError) => {

        let response = await APIgetDocUC(r, setError)

        if (response) {
            let arrDataR = csvToArr(response, ";")
            setArrData(arrDataR)
        } else {
            setArrData([])
        }

    }, [])

    useEffect(() => {
        if (!logincheck) {
            loading(true);
            const logOUT = async () => {
                document.cookie = 'page=;Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                await APIlogout();
                window.location.reload();

            }
            logOUT();
            loading(false);
        }
    }, [logincheck, loading]);

    const generate = useCallback(async () => {
        let r = await APIgenerateDocUC(token, dateStart, dateEnd, setError)

        retriveDoc(r, setError)

    }, [token, retriveDoc, dateStart, dateEnd])

    useMemo(async () => {
        let auth = await APIauthUC(setError)
        setToken(auth)

    }, [])

    function csvToArr(stringVal, splitter) {
        if (stringVal !== "") {
            const [keys, ...rest] = stringVal
                .trim()
                .split("\n")
                .map((item) => item.split(splitter));

            const formedArr = rest.map((item) => {
                const object = {};
                keys.forEach((key, index) => {
                    (object[key.trim()] = item.at(index))
                });

                return object;
            });

            return formedArr
        } else {
            return null
        }


    }

    const columns = [
        {
            id: 'Campagna',
            selector: row => row["Campagna"],
            cell: row => row["Campagna"],
            name: "Campagna",
            compact: true,
            sortable: false,
            center: true
        },
        {
            id: 'Messaggio trascritto',
            selector: row => row["Messaggio trascritto"],
            cell: row => <div style={{ fontSize: 16, padding: 5 }}>{row["Messaggio trascritto"]}</div>,
            name: "Messaggio trascritto",
            compact: false,
            sortable: false,
            center: true,
            width: "350px"
        },
        {
            id: 'Sentiment',
            selector: row => row["Sentiment"],
            cell: row => row["Sentiment"],
            name: "Sentiment",
            compact: true,
            sortable: false,
            center: true
        },
        {
            id: 'Intent',
            selector: row => row["Intent"],
            cell: row => row["Intent"],
            name: "Intent",
            compact: true,
            sortable: false,
            center: true
        },
        {
            id: "Dataora",
            selector: row => row.Dataora,
            cell: row => row.Dataora,
            name: "Data / ora",
            compact: true,
            sortable: true,
            center: true
        },
        {
            id: 'Numero chiamato',
            selector: row => row["Numero chiamato"],
            cell: row => row["Numero chiamato"],
            name: "Numero chiamato",
            compact: true,
            sortable: false,
            center: true
        },
        {
            id: 'Durata chiamata',
            selector: row => row["Durata chiamata"],
            cell: row => row["Durata chiamata"],
            name: "Durata chiamata",
            compact: true,
            sortable: false,
            center: true
        },
        {
            id: 'Dati personali',
            selector: row => row["Dati personali"],
            cell: row => row["Dati personali"],
            name: "Dati personali",
            compact: true,
            sortable: false,
            center: true
        },
        {
            id: 'Keyword 1',
            selector: row => row["Keyword 1"],
            cell: row => row["Keyword 1"],
            name: "Keyword 1",
            compact: true,
            sortable: false,

            center: true
        },
        {
            id: 'Keyword 2',
            selector: row => row["Keyword 2"],
            cell: row => row["Keyword 2"],
            name: "Keyword 2",
            compact: true,
            sortable: false,
            center: true
        },
        {
            id: 'Keyword 3',
            selector: row => row["Keyword 3"],
            cell: row => row["Keyword 3"],
            name: "Keyword 3",
            compact: true,
            sortable: false,
            center: true
        },
        {
            id: 'Keyword 4',
            selector: row => row["Keyword 4"],
            cell: row => row["Keyword 4"],
            name: "Keyword 4",
            compact: true,
            sortable: false,
            center: true
        },
        {
            id: 'Keyword 5',
            selector: row => row["Keyword 5"],
            cell: row => row["Keyword 5"],
            name: "Keyword 5",
            compact: true,
            sortable: false,
            center: true
        }
    ]

    return (

        <Grid>

            <Grid.Column mobile={16} tablet={16} computer={16}>
                <Nav loading={loading} size={size} role={role} />
            </Grid.Column>

            <Grid.Column style={{ margin: "20px" }} mobile={16} tablet={16} computer={16}>


                <Label style={{ display: "block", marginBottom: "5px" }}>SELEZIONA IL PERIODO DI RICERCA (DAL  - AL)</Label>

                <Input max={dateEnd !== "" ? dateEnd : ""} style={{ marginRight: "5px" }} value={dateStart} onChange={(event, data) => { setDateStart(data.value) }} type={"date"} />

                <Input min={dateStart !== "" ? dateStart : ""} value={dateEnd} onChange={(event, data) => { setDateEnd(data.value) }} type={"date"} />

                <Button style={{ marginLeft: "5px" }} color={"green"} circular onClick={generate} icon={<Icon name={"find"} />} content={"RICERCA"} />
                <Button style={{ marginLeft: "5px" }} color={"blue"} circular onClick={generate} icon={<Icon name={"refresh"} />} content={"AGGIORNA"} />
                <Segment padded color='yellow'
                    style={{
                        minHeight: '400px',
                        marginTop: '0.5rem',
                        marginBottom: '2em',
                        borderRadius: '2rem',
                        paddingLeft: '2em',
                        paddingRight: '2em'
                    }}>

                    <Container fluid style={{ borderRadius: '1rem', marginTop: '2rem' }}>

                        <DataTable inverted striped noHeader
                            customStyles={{

                                headCells: {
                                    style: {
                                        backgroundColor: '#1c2545',
                                        color: "white",
                                        textTransform: 'uppercase',
                                        whiteSpace: 'normal!important',
                                        textOverflow: 'unset!important',
                                        textAlign: 'center!important'
                                    }
                                },

                                header: {
                                    style: {

                                        overflow: 'visible',
                                        margin: 0,
                                        padding: 0,
                                        paddintBottom: 0,
                                    }
                                },

                                subHeader: {
                                    style: {
                                        display: 'block',
                                        padding: 0,
                                        margin: 0
                                    },
                                }


                            }}
                            columns={columns}
                            data={arrData}
                            keyField='id'
                            clearSelectedRows={false}
                            pagination
                            defaultSortFieldId={4}
                        // defaultSortAsc={"​​​​false"}​​​​
                        />

                    </Container>

                    {/* <p>{error}</p> */}
                </Segment>
            </Grid.Column>

        </Grid>


    )
}

export default Admin;