import { APIcheckSignin } from '../apicalls';

const LoginCheck = async () => {

  let check = {};

  try {
    check = await APIcheckSignin(); // SE CHECK ritorna TRUE
    // console.log(check.data);
    return (true, check.data.role);
  }
  catch(err) {

    if (err.response) {
      check.status = err.response.status;
      check.data = err.response.data;
    }

    else{
      check.status = 'NOT_AVAILABLE';
    }

    return false;
  }
}

export default LoginCheck;

