import React from "react";

const Loading = () => {

    return (
        <div className="ui segment" style={{ height: "100vh", width: "100vw", backgroundColor: "white", borderRadius: "0px" }}>
            <div className="ui active inverted dimmer" style={{ backgroundColor: "transparent" }}>
                <div className="ui massive text loader"></div>
            </div>
        </div>
    )
}

export default Loading