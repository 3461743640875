import React, { useEffect, useState, useCallback } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import '../css/App.css';
import 'react-toastify/dist/ReactToastify.css';
import Nav from './Nav';
import { Grid, Header, Modal, Label, Progress, Dropdown, Button, Form, Segment, Select, Icon, Message, Transition, Container} from 'semantic-ui-react'
import {
    APIlogout,
    getNlpTasks,
    getNlpServices,
    executeNlpMassive
} from '../apicalls';

const SendMassiveNlp = ({ role, logincheck, loading, size }) => {

    useEffect(() => {
        if (!logincheck) {
            loading(true);
            const logOUT = async () => {
                document.cookie = 'page=;Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                await APIlogout();
                window.location.reload();
            }
            logOUT();
            loading(false);
        }
    }, [logincheck, loading]);

    const [fileLoaded, setFileLoaded] = useState(false);
    const [progressValue, setProgressValue] = useState(0)
    const [totalEntries, setTotalEntries] = useState(0)
    const [textValue, setTextValue] = useState([])
    const [expectedResp, setExpectedResp] = useState([])
    const [status, setStatus] = useState([])
    const [taskResp, setTaskResp] = useState([]);
    const [csvResponse, setCsvResponse] = useState([]);
    const [progressFinished, setProgressFinished] = useState('none')
    const [nlpValue, setNlpValue] = useState('davinci')
    const [executeValue, setExecuteValue] = useState()
    const [executeOptions, setExecuteOptions] = useState([])
    const [serviceOptions, setServiceOptions] = useState([])
    const [apiSend, setApiSend] = useState(false)
    const [open, setOpen] = useState(false)
    const [outcome, setOutcome] = useState({})
    const [mockActivated, setMockActivated] = useState(false)

    useEffect(() => {
        const getTaks = async () => {
            const nlpServices = await getNlpServices()
            
            setServiceOptions(nlpServices.map((task) => {
                return {
                    key: task.name,
                    value: task.name,
                    text: task.name
                }
            }))
            
            const nlpTasks = await getNlpTasks()

            setExecuteOptions(nlpTasks.map((task) => {
                return {
                    key: task.id,
                    value: task.id,
                    text: task.name
                }
            }))
            setExecuteValue(nlpTasks[0].id)
        }
        getTaks()
    }, [])

    useEffect(() => {
        if (outcome.sent === true) {
            setTimeout(() => {
                setOutcome({
                    ...outcome,
                    sent: undefined
                })
            }, 5000)
        }
    }, [outcome])


    const loadList = (e) => {
        const file = e['0'];
        const size = e[0]?.size;

        if (size < 5000000) {

            const reader = new FileReader();
            reader.addEventListener("load", async () => {
                try {
                    //controllo estensione
                    if (/\.(csv)$/i.test(file.name)) {
                        const res = reader.result.split('\r\n');
                        const textValues = [];
                        const taskValues = [];
                        setTotalEntries(res.length - 1);
                        for (let x = 0; x < res.length - 1; x++) {
                            let line = res[x].split(';');
                            textValues[x] = line[0];
                            taskValues[x] = line[1];
                        }
                        setTextValue(textValues);
                        setExpectedResp(taskValues);
                        setFileLoaded(true);
                    } else {
                        setOutcome({
                            sent: true,
                            esito: 'KO',
                            message: "Formato file non valido"
                        })
                    }

                }
                catch (err) {
                    console.log(err)
                }

            }, false);

            if (file) reader.readAsText(file);
        }
    }

    const activateMock = useCallback((index) => {
        if(document.getElementById("mockCheckbox").checked){
            setMockActivated(true)
        } else {
            setMockActivated(false)
        }
    },[])

    const download = function (data) {

        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.setAttribute('href', url)
        a.setAttribute('download', 'download.csv');
        a.click()
    }

    const addLineCsv = useCallback((index,last) => {

        const tempCsvResp = csvResponse

        //genero riga csv
        if(last){
        tempCsvResp.unshift(" ");
        let okEntries = 0
        for(let singleStatus of status){
            if(singleStatus === "OK") okEntries++
        }
        const updRes = "Data e ora: " + new Date(Date.now()).toISOString() + ';Id: ' + executeValue + ';Numero totale record :' + totalEntries + ";Numero record ok: " + okEntries;
        tempCsvResp.unshift(updRes);
        } else {
            
        const updRes = textValue[index] + ';' + expectedResp[index] + ';' + status[index] + ';' + taskResp[index];
        tempCsvResp[index] = updRes;
        }
        setCsvResponse(tempCsvResp);

    }, [csvResponse, executeValue, totalEntries, taskResp, textValue, expectedResp, status]);

    const clearData = useCallback(() => {

        document.getElementById("file-list").value="";
        let succesful = totalEntries

        for(let singleStatus of status){
            if(singleStatus==='ERROR') succesful--
        }

        switch (succesful) {
            case totalEntries:
                toast.success(`${succesful}/${totalEntries} richieste inviate correttamente!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    });
                break;
            case 0:
                toast.error(`Errore durante l'invio delle richieste (${succesful}/${totalEntries} inviate)`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    });
                break;
            default:
                toast.warn(`Errori durante l'invio delle richieste (${succesful}/${totalEntries} inviate)`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    });
                break;
        }

        download(csvResponse.join('\n'));
        setOpen(false);
        setFileLoaded(false);
        setProgressValue(0);
        setTotalEntries(0);
        setTextValue([]);
        setExpectedResp([]);
        setStatus([]);
        setTaskResp([]);
        setCsvResponse([]);
        setProgressFinished('none');
        setOutcome({});

    }, [csvResponse, status, totalEntries])

    const mockSendApi = useCallback(async (index) => {

        let tempStatus = status;
        let tempTaskResp = taskResp;
        setApiSend(true);

        //genero stringa che simuli risposta dell'API
        const responseStr = `{
            "computed": "Buongiorno, vorrei informazioni sulla mia bolletta di agosto, codice cliente 1234",
            "nlp": "Buongiorno, vorrei informazioni sulla mia bolletta di agosto, codice cliente 1234",
            "tasks": {
                "get-tree-classification": "Amministrativo|Informazioni lettura bollette"
            }
        }`

        try {
            const response = JSON.parse(responseStr);
            //recupero nome del task per estrarre il valore dall'oggetto 'tasks'
            for (let option of executeOptions) {
                if (option.value === executeValue) tempTaskResp[index] = response.tasks[option.text];
            }

            setTaskResp(tempTaskResp)

            if(Math.random() < 0.5){    //add random error 
                tempStatus[index] = 'ERROR'
                setStatus(tempStatus)
            } else{
                if (taskResp[index] === expectedResp[index]) tempStatus[index] = 'OK'
                else tempStatus[index] = 'KO'
            }

        } catch (err) {
            tempStatus[index] = 'ERROR'
            setStatus(tempStatus)
        } finally {
            setTimeout(addLineCsv(index,false), 1000)
            setApiSend(false);
        }

    }, [status, taskResp, addLineCsv, expectedResp, executeOptions, executeValue]);

    const sendApi = useCallback(async (index) => {

        let tempStatus = status;
        let tempTaskResp = taskResp;
        setApiSend(true);

        const body = {
            inputType: 'text',
            text: textValue[index],
            nlp: nlpValue,
            execute: [executeValue]
        }

        try {
            const response = await executeNlpMassive(body)
            //recupero nome del task per estrarre il valore dall'oggetto 'tasks'
            for (let option of executeOptions) {
                if (option.value === executeValue) tempTaskResp[index] = response.tasks[option.text];
            }
            setTaskResp(tempTaskResp)
            if (taskResp[index] === expectedResp[index]) tempStatus[index] = 'OK'
            else tempStatus[index] = 'KO'

        } catch (err) {
            tempStatus[index] = 'ERROR'
            setStatus(tempStatus)
        } finally {
            setApiSend(false);
            addLineCsv(index,false)
        }

    }, [status, taskResp, textValue, nlpValue, executeValue, expectedResp, executeOptions, addLineCsv])

    const sendMultiple = useCallback(async (event) => {

        if (fileLoaded) {

            //open modal
            setOpen(true)

            for (let x = 0; x <= totalEntries - 1; x++) {
                setProgressValue(x);
                if(mockActivated){
                    await mockSendApi(x);
                } else {
                    await sendApi(x)
                }
            }
            addLineCsv(totalEntries,true)
            setProgressValue(totalEntries);
            setProgressFinished('inline');

        } else {
            setOutcome({
                sent: true,
                esito: 'KO',
                message: "File non selezionato"
            })
        }

    }, [fileLoaded, addLineCsv, totalEntries, mockActivated, mockSendApi, sendApi]);

    return (
        <Grid>

            <Grid.Column mobile={16} tablet={16} computer={16}>
                <Nav loading={loading} size={size} role={role} />
            </Grid.Column>

            <Form
                style={{
                    maxWidth: '500px',
                    marginTop: '40px'
                }}
            >
                {/* <Checkbox 
                        toggle 
                        label = 'Invio Mock'
                        id = 'mockCheckbox'
                        onChange={() =>{ activateMock() }}
                        /> */}

                <Grid.Column mobile={16} tablet={16} computer={16} style={{ width: '400px' }}>
                    <Button
                        content='Carica file CSV'
                        icon='paperclip'
                        labelPosition='right'
                        style={{ margin: '15px 0', float: 'center' }}
                        basic
                        onClick={() => { document.getElementById('uploadLbl').click() }}
                    />
                    <label id='uploadLbl' style={{ display: 'none' }} htmlFor="file-list"></label>

                    <Container>
                        <input style={{ display: "none" }} onChange={(e) => loadList(e.target.files)} id="file-list" accept="file/*; image/*" type="file"></input>
                    </Container>

                    <Segment
                        style={{
                            padding: '5px'
                        }}
                    >
                        <Label color='teal' attached='top left'>SERVIZIO NLP</Label>
                        <Select
                            style={{
                                width: '100%'
                            }}
                            placeholder='Seleziona il servizio di AI'
                            value={nlpValue}
                            options={serviceOptions}
                            onChange={(_e, data) => { setNlpValue(data.value) }}
                        />
                    </Segment>

                    <Segment
                        style={{
                            padding: '5px'
                        }}
                    >
                        <Label color='blue' attached='top left'>TASK DA ESEGUIRE</Label>
                        <Dropdown
                            options={executeOptions}
                            placeholder='Seleziona i task da eseguire'
                            selection
                            fluid
                            value={executeValue}
                            onChange={(_e, data) => { setExecuteValue(data.value) }}
                        />
                    </Segment>

                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored" />
                    
                    <Button
                        content='Invia'
                        icon='send'
                        labelPosition='right'
                        style={{ margin: '15px 0', float: 'right' }}
                        basic
                        onClick={() => { sendMultiple() }}
                        loading={apiSend}
                    />
                    <Modal
                        basic
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        open={open}
                        size='small'
                    >
                        <Header icon>
                            <Icon name='envelope' />
                            Avanzamento Richieste
                        </Header>
                        <Modal.Content>
                            <Progress value={progressValue} total={totalEntries} color='green' progress='ratio' />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='green' style={{ display: progressFinished }} inverted onClick={() => clearData()}>
                                Chiudi & Scarica
                            </Button>
                        </Modal.Actions>
                    </Modal>

                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={16} style={{ width: '400px' }}>
                    <Transition visible={outcome.sent === true} animation='fade down' duration={500}>
                        <Message positive={outcome.esito === 'OK'} negative={outcome.esito === 'KO'}>
                            <p>{outcome.message}</p>
                        </Message>
                    </Transition>
                </Grid.Column>
            </Form>

        </Grid >
    )
}

export default SendMassiveNlp;