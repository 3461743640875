import React from 'react';
import { Button, Icon } from 'semantic-ui-react'
import { navigate } from '@reach/router';

const MenuButton = ({ label, color, route }) => {

    return (
        <div>
            <Button
                basic
                size='big'
                style={{ margin: '5px' }}
                color={color}
                onClick={() => { navigate(route) }}
            >
                {label}
            </Button>
        </div>
    )
}

export default MenuButton;