import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { APIsignin, APIchangePass } from '../apicalls';
import '../css/App.css';

const Login = () => {
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [oldpass, setOldpass] = useState('');
    const [newpass, setNewpass] = useState('');
    const [errmsg, setErrmsg] = useState('');
    const [changePass, setChangePass] = useState(false);
    const [confirmNewPass, setConfirmNewPass] = useState('');

    const [groupOn, setGroupOn] = useState(false)
    const [group, setGroup] = useState('')
    const [groups, setGroups] = useState([])

    const setGroupOptions = (groups) => {
        let options = []
        groups.forEach(group => {
            options.push(
                {
                    text: group.name,
                    value: group.id
                }
            )
        });

        setGroups(options)
    }

    const onGroupChange = (event, groups) => { //navigationID
        setGroup(groups.value);
    }

    const submitUP = async (event) => {
        event.preventDefault();
        let risp = {};
        try {
            risp = await APIsignin(user, pass, group);
           
            if (risp.data.isSysadmin) {
                setGroupOn(true)
                setGroupOptions(risp.data.groups)
                setErrmsg('');
            } else {
                setErrmsg('');
                window.location.reload();
            }
        }
        catch (err) {
            if (err.response) {
                risp.status = err.response.status;
                risp.data = err.response.data;
                if (risp.status === 400) {
                    // setErrmsg(risp.data.errors[0].message);
                    if (risp.data.errors[0].message === "Password has expired" || risp.data.errors[0].message === "User has a temporary password, password must be changed") {
                        setChangePass(true)
                        setErrmsg("Password scaduta");
                    }

                    else {
                        setChangePass(false)
                        setErrmsg('Password Errata')
                    }

                }
                else if (risp.status === 404) {
                    setErrmsg(err.response.statusText)
                }
            }
            else {
                setErrmsg('Generic error :(')
            }
        }

    }

    const changePassword = async (event) => {
        event.preventDefault();

        if (newpass !== confirmNewPass) {
            setErrmsg('Password non corrispondenti');
            return
        }

        let inputs = {
            "username": user,
            "oldPassword": oldpass,
            "newPassword": newpass
        }

        let risp = {};
        try {
            risp = await APIchangePass(inputs);
            setErrmsg('');
            window.location.reload();

        }
        catch (err) {
            if (err.response) {
                risp.status = err.response.status;
                risp.data = err.response.data;


                if (risp.status === 404) {
                    setErrmsg(err.response.statusText)
                }
            }
            else {
                setErrmsg('Generic error :(')
            }
        }
    }

    if (changePass) {
        return (
            <div className="cagelogin">
               <div style={{ display: "flex", alignContent: "center", justifyContent: "center", flexDirection: "column" , border: "1px solid #F2F2F2", backgroundColor: "#F2F2F2", width: "600px", height: "600px", margin: "0 auto", padding: "30px" }}>
                    <div style={{ display: "flex", justifyContent: 'center', paddingBottom: '30px' }} className='content'>
                        <img style={{ width: '250px' }} src="https://inx-assets.s3.eu-south-1.amazonaws.com/inxide/Inxide-logo.png" alt="" />
                    </div>
                    <form
                        className="ui form"
                        onSubmit={changePassword}>
                        <div className="field">
                            <label>Username</label>
                            <div className="ui left icon input">
                                <input
                                    type="text"
                                    name="first-name"
                                    placeholder="Username"
                                    value={user}
                                    onChange={(event) => setUser(event.target.value)} />
                                <i className="user icon"></i>
                            </div>
                        </div>
                        <div className="field">
                            <label>Vecchia Password</label>
                            <div className="ui left icon input">
                                <input
                                    type="password"
                                    name="last-name"
                                    placeholder="old password"
                                    value={oldpass}
                                    onChange={(event) => setOldpass(event.target.value)}
                                />
                                <i className="lock icon"></i>
                            </div>
                        </div>

                        <div className="field">
                            <label>Nuova Password</label>
                            <div className="ui left icon input">
                                <input
                                    type="password"
                                    name="new password"
                                    placeholder="new password"
                                    value={newpass}
                                    onChange={(event) => setNewpass(event.target.value)}
                                />
                                <i className="lock icon"></i>
                            </div>
                        </div>
                        <div className="field">
                            <label>Nuova Password</label>
                            <div className="ui left icon input">
                                <input
                                    type="password"
                                    name="confirm new password"
                                    placeholder="confirm new password"
                                    value={confirmNewPass}
                                    onChange={(event) => setConfirmNewPass(event.target.value)}
                                />
                                <i className="lock icon"></i>
                            </div>
                        </div>
                        <p style={{ textAlign: "center" }}><b>Criteri nuova password - almeno: </b>8 Caratteri - 1 Maiscola - 2 Cifre - 1 simbolo</p>
                        <button style={{ width: "400px" }} className="ui fluid blue submit button" type="submit">Invia</button>
                    </form>
                    <p style={{ marginTop: "10px", color: "red", textAlign: "center" }}>{errmsg}</p>
                </div>
            </div>
        )
    } else {
        return (
            <div className="cagelogin">
                <div style={{ display: "flex", alignContent: "center", justifyContent: "center", flexDirection: "column" , border: "1px solid #F2F2F2", backgroundColor: "#F2F2F2", width: "600px", height: "600px", margin: "0 auto", padding: "30px" }}>
                    <div style={{ display: "flex", justifyContent: 'center', paddingBottom: '30px' }} className='content'>
                        <img style={{ width: '250px' }} src="https://inx-assets.s3.eu-south-1.amazonaws.com/inxide/Inxide-logo.png" alt="" />
                    </div>
                    <form
                        className="ui form"
                        onSubmit={submitUP}
                    >
                        <div className="field">
                            <label>Username</label>
                            <div className="ui left icon input">
                                <input
                                    type="text"
                                    name="first-name"
                                    placeholder="Username"
                                    value={user}
                                    onChange={(event) => setUser(event.target.value)} />
                                <i className="user icon"></i>
                            </div>
                        </div>
                        <div className="field">
                            <label>Password</label>
                            <div className="ui left icon input">
                                <input
                                    type="password"
                                    name="last-name"
                                    placeholder="Password"
                                    value={pass}
                                    onChange={(event) => setPass(event.target.value)}
                                />
                                <i className="lock icon"></i>
                            </div>
                        </div>
                        {
                            groupOn
                            &&
                            <div className="field">
                                <label>Gruppo</label>
                                <Dropdown
                                    type="text"
                                    name="last-name"
                                    onChange={onGroupChange}
                                    options={groups}
                                    placeholder='Scegli il gruppo'
                                    selection
                                    value={group}
                                />
                            </div>
                        }
                        <button style={{ width: "400px" }} className="ui fluid blue submit button" type="submit">Login</button>
                    </form>
                    <p style={{ marginTop: "10px", color: "red" }}>{errmsg}</p>
                </div>
            </div>
        )
    }
}

export default Login;