import React, { useState, useEffect } from 'react';
import { Router, navigate, createHistory } from '@reach/router';
import Login from './components/Login';
import Admin from './components/Admin';
import Home from './components/Home';
import SendNlp from './components/SendNlp';
import SendMassiveNlp from './components/SendMassiveNlp';


import LoginCheck from './components/LoginCheck';

import './css/App.css';
import 'semantic-ui-css/semantic.min.css';
// import { Divider } from 'semantic-ui-react';
import Loading from './components/Loading';


const App = () => {
    const [logincheck, setLogincheck] = useState(false);
    const [loading, setLoading] = useState(true);
    let history = createHistory(window);



    const size = useWindowSize();
    function useWindowSize() {
        const [windowSize, setWindowSize] = useState(undefined);
        useEffect(() => {
            function handleResize() {
                setWindowSize(window.innerWidth);
            }
            window.addEventListener("resize", handleResize);
            handleResize();
            return () => window.removeEventListener("resize", handleResize);
        }, []);
        return windowSize;
    }

    useEffect(() => {

        const initialize = async () => {
            setLoading(true)
            const check = await LoginCheck();
            setLogincheck(check);
            setLoading(false)
        }
        initialize();

        if (logincheck) {
            navigate('/home');

        } else {
            navigate('/login');
        }

    }, [logincheck])

    history.listen(({ location, action }) => {
        if (action === 'POP') {
            navigate('/home');
        }

        if (action === 'PUSH') {
            navigate('/home');
        }
    });

    if (loading) {
        return (
            <Loading />
        )
    }

    if (logincheck) { // se login è FALSE
        return (
            <Router>
                <Home size={size} loading={setLoading} logincheck={logincheck} path="/home" role={logincheck} />
                <Admin size={size} loading={setLoading} logincheck={logincheck} path="/admin" role={logincheck} />
                <SendNlp size={size} loading={setLoading} logincheck={logincheck} path="/sendnlp" role={logincheck} />
                <SendMassiveNlp size={size} loading={setLoading} logincheck={logincheck} path="/sendmassivenlp" role={logincheck} />
            </Router>
        )

    } else {
        return (
            <Router>
                <Login size={size} loading={setLoading} path="/login" />
            </Router>
        )
    }
}

export default App;