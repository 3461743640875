import React, { useEffect } from 'react';
import '../css/App.css';
import MenuButton from '../components/menuButton'
import Nav from './Nav';
import { Grid } from 'semantic-ui-react'
import {
    APIlogout
} from '../apicalls';


const Home = ({ role, logincheck, loading, size }) => {

    useEffect(() => {
        if (!logincheck) {
            loading(true);
            const logOUT = async () => {
                document.cookie = 'page=;Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                await APIlogout();
                window.location.reload();
            }
            logOUT();
            loading(false);
        }
    }, [logincheck, loading]);

    return (
        <Grid>

            <Grid.Column mobile={16} tablet={16} computer={16}>
                <Nav loading={loading} size={size} role={role} />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={16} computer={16}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        flexWrap: 'wrap',
                        alignItems: 'center'
                    }}>

                    <MenuButton label='Invio richiesta NLP' route='/sendnlp' />
                    <MenuButton label='Invio richiesta massiva NLP' route='/sendmassivenlp' />
                    <MenuButton label='Report uContact' route='/admin' />

                </div>
            </Grid.Column>

        </Grid >
    )
}

export default Home;